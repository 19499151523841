import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/PageNotFound';

export default function ({}) {
  return (
    <>
      <SEO title={'404 Page Not Found'} />

      <Article />
    </>
  );
}
