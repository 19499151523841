import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import TOC from '../../../components/TOC';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SavingsIcon from '@mui/icons-material/Savings';
import AddressSearchPopup from '../../AddressSearch/AddressSearchPopup';
import SCLandingWrapper from '../../SCLandingWrapper';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import '../index.css';
import './index.css';

function Article({ siteConfig, serviceAddress }) {
  const [showAddressSearch, setShowAddressSearch] = useState(null);

  return (
    <div className="page-container text-container page-404">
      <h1>Oops!</h1>
      <h5 className="est-read-time">Page Not Found</h5>
      <div className="page">
        <div className="inner">
          <div className="article-text">
            <h2>Error 404</h2>

            <p>
              The page you are looking for has either moved or never existed.
            </p>

            <p>
              <a href={'/'} className="link">
                Click here
              </a>{' '}
              to return to the home page.
            </p>

            <div className="parallax-image">
              <ParallaxProvider>
                <Parallax speed={-10}>
                  <SentimentVeryDissatisfiedIcon className="top-icon" />
                </Parallax>
              </ParallaxProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
